import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Art of Non-Violent Communication`}</h1>
    <p><strong parentName="p">{`The Mission:`}</strong>{` How to finance the business?/
`}<strong parentName="p">{`Takeaways:`}</strong>{` LOL + how to argue effectively`}</p>
    <p>{`Today will be a quickie. Our day job’s went overtime, so I’ll keep this short.`}</p>
    <p>{`I’m helping my wife with her ecommerce business, and an interesting convo happened today.`}</p>
    <p>{`You see - we’re going to need $1m+ of working capital in 2021 for things like marketing, inventory, operations etc.. next year.`}</p>
    <p><strong parentName="p">{`So where does this money come from?`}</strong></p>
    <ol>
      <li parentName="ol">{`Personal Savings? (We’ve already put in a lot of personal life savings into the biz)`}</li>
      <li parentName="ol">{`Business loans / line of credit? (biz is young, we may not qualify for much)`}</li>
      <li parentName="ol">{`Private investors?`}</li>
    </ol>
    <p><strong parentName="p">{`Each option has pros and cons.`}</strong>{`
I started explaining them to my wife, and she hit me with a quick one: `}</p>
    <p><strong parentName="p">{`“I don’t wanna give up any equity”`}</strong></p>
    <p>{`“B..bu..but what if it helps us grow faster?”`}</p>
    <p><strong parentName="p">{`“I don’t want to give up equity.”`}</strong>{`  `}</p>
    <p>{`“Yea but it would be nice to have strategic investors, they can help with..”`}</p>
    <p><strong parentName="p">{`“I don’t want to give up equity.”`}</strong>{`  `}</p>
    <p>{`“...okay…..see the thing is, sometimes you get a smaller slice of a bigger pie”`}</p>
    <p>{`“Ooh. Pie sounds good” `}<strong parentName="p">{`gets up and walks to kitchen`}</strong>{` and while she walks away, hits me with one last: “ `}<strong parentName="p">{`I just don’t want to give up any equity”.`}</strong>{`  `}</p>
    <p>{`……yea boiiiiiiiiiiiiii! working with family is the best!!! Brb while I go light myself on fire.`}</p>
    <p>{`Old Shaan would have been really frustrated at the convo.`}</p>
    <p>{`Old Shaan= react poorly. “She’s so hardheaded”, “she’s so stubborn”, “why doesn’t she just get it”.`}</p>
    <p>{`New shaan = react like a champion. “Alright babe, I feel ya, I hate giving up equity too unless I absolutely have to. Why don’t I come up with a handful of options, and you can pick which one you think is best on friday?”`}</p>
    <p>{`Old shaan results in an argument, and further disconnects. Plus then this topic becomes a sensitive wound. We both remember the argument, so we actually avoid talking about it again.`}</p>
    <p>{`New shaan results in happiness, and a feeling of being in this together. The topic is a defused bomb, we don’t fear touching it again in a day or two.`}</p>
    <p>{`This is learning the Art of Non-Violent Communication. Emmett Shear (the ceo of twitch) recommended the book to me a while back, saying it helped him a lot. I haven’t read the book, but I downed 3 summaries (i really like `}<a parentName="p" {...{
        "href": "https://fourminutebooks.com/"
      }}><strong parentName="a">{`fourminutebooks.com`}</strong></a>{` )`}</p>
    <p><strong parentName="p">{`One of the book's takeaways is:`}</strong></p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Don’t judge. It’s an ineffective strategy that leads to pain.`}</strong></li>
    </ol>
    <p>{`So instead of judging her response as her being “stubborn” or “hard headed”, I’ve practiced ditching judgement. It’s useless. Judgement leads to criticism, criticism leads to defensiveness.`}</p>
    <p>{`And nobody likes to talk to someone who’s being defensive. So why put them in that position?`}</p>
    <p><strong parentName="p">{`The second tactic for me that’s helped is:`}</strong></p>
    <p><strong parentName="p">{`2. Get Curious.`}</strong></p>
    <p><strong parentName="p">{`Rather than react to what people say, i respond.`}</strong></p>
    <p>{`Response = a thoughtful action back`}</p>
    <p>{`Reaction = a reflex (without thinking)`}</p>
    <p>{`My response is almost always `}<strong parentName="p">{`curiosity`}</strong>{`.`}</p>
    <p>{`When someone compliments me, I don’t deflect (“oh this old jacket?”), and I don’t get excited either. I just get curious. What makes you say that? What do you like about it? What could make it even better?`}</p>
    <p>{`When someone disagrees with me - instead of trying to convince them (rookie move), I get curious. “What makes you say that? You seem really against that, I’m curious why? Hm, I don’t feel like you’re excited by my idea, what makes you hesitate?”`}</p>
    <p>{`The exec coach at twitch, Glo, taught us there are 3 ways to listen:`}</p>
    <ul>
      <li parentName="ul">{`Listen to `}<strong parentName="li">{`win`}</strong>{` - like a debate, you’re listening to find a moment to pounce and ‘win’ the argument`}</li>
      <li parentName="ul">{`Listen to `}<strong parentName="li">{`fix`}</strong>{` - (most men are guilty of this). Listening to try and find the solution/fix to what’s being said`}</li>
      <li parentName="ul">{`Listen to `}<strong parentName="li">{`learn`}</strong>{` - genuinely listening, with curiosity, with a goal of understanding the situation and the other person’s feelings.`}</li>
    </ul>
    <p>{`This shit sounds cheesy as hell as I type it, but it actually ends up being super important when dealing with people.`}</p>
    <p>{`So I try to notice when I’m listening to win or fix...and then make the shift to listening to learn.`}</p>
    <p><strong parentName="p">{`3. The last tool is from Man’s Search for Meaning: “Everything can be taken from a man but one thing: the last of the human freedoms—to choose one’s attitude in any given set of circumstances, to choose one’s way.” -Viktor Frankl”`}</strong></p>
    <p>{`I never give up this freedom. The freedom to feel how I want. Even when a conversation is not going my way, I’m not going to give up my good mood for it.`}</p>
    <p>{`This all sounds easy, but it’s hard as f*ck to do. It takes a lot of practice, and I fall back into my old ways all the time. But I’m getting better at it, and wanted to share because I’m sure many of you run into situations like this everyday.`}</p>
    <p>{`A stupid boss, a nagging spouse, a jerk at the store…`}</p>
    <p>{`Those are small practice fields. Tiny opportunities to sharpen my skills at `}<strong parentName="p">{`ditching judgement, getting curious, and deciding to feel good regardless of the circumstances.`}</strong></p>
    <p>{`until tomorrow!`}<br parentName="p"></br>{`
`}{`shaan`}</p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'https://fourminutebooks.com/',
        label: 'Four Minute Books'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.growth_is_math_art_science.label,
      slug: slugify(postMap.growth_is_math_art_science.slug)
    }} nextButton={{
      text: postMap.firing_lots_of_growth_bullets.label,
      slug: slugify(postMap.firing_lots_of_growth_bullets.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      